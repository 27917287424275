


























import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import HomeExplorerCard from '~/components/cards/HomeExplorerCard.vue';
import HomeAppCard from '~/components/cards/HomeAppCard.vue';
import HomeBanner from '~/components/partials/HomeBanner.vue';
import BlogFeed from '~/components/lists/BlogFeed.vue';
import EventFeed from '~/components/lists/EventFeed.vue';
import DestinationsFeed from '~/components/lists/DestinationsFeed.vue';
import HomeMainCards from '~/components/lists/HomeMainCards.vue';

@Component({
  layout: 'default',
  components: {
    HomeExplorerCard,
    HomeAppCard,
    HomeBanner,
    BlogFeed,
    EventFeed,
    DestinationsFeed,
    HomeMainCards,
  },
  middleware: ['noauth', 'website/homepage'],
  computed: {
    ...mapState('website', [
      'homeContent',
    ]),
    ...mapState('explorer', [
      'destinationsHighlighted',
      'eventsSuggestions',
    ]),
  },
  head () {
    const self: any = this;
    const locale: string = self.$store.state.lang.locale;
    return {
      title: `${self.$t('home.meta.title')}`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${self.$t('home.meta.title')}`,
        },
        {
          hid: 'description',
          name: 'description',
          content: `${self.$t('home.meta.description')}`,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: `${self.$t('home.meta.description')}`,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://www.onpiste.com/img/home/home_banner.jpeg',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://www.onpiste.com/${locale === 'fr' ? '' : locale}`,
        },
      ],
    };
  },
})
export default class Index extends Vue {
  homeContent: Types.HomeContent;
  destinationsHighlighted: Types.DestinationItem[];
  eventsSuggestions: Types.Event[];
}

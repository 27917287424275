


























import { Vue, Component, Prop } from 'vue-property-decorator';

import { objectIsEmpty } from '~/utils/common.js';

@Component
export default class HomeMainCard extends Vue {
  @Prop(Object) content: {};

  /* Computed skeleton handle */
  get contentIsEmpty () {
    return objectIsEmpty(this.content);
  }
}

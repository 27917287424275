





























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class HomeBanner extends Vue {
  @Prop(Object) banner: Types.HomeContent;
}

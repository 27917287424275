










































import { Vue, Component, Prop } from 'vue-property-decorator';

import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import DestinationCard from '~/components/cards/DestinationCard.vue';
import GlobalSwiper from '~/components/partials/GlobalSwiper.vue';
import MapOutline from '~/components/icons/MapOutline.vue';

@Component({
  components: { ChevronRightSolid, DestinationCard, GlobalSwiper, MapOutline },
})
export default class DestinationsFeed extends Vue {
  @Prop(Array) destinations: Types.DestinationItem[];

  /* Skeleton length of fake cards to display based on user's window device */
  get skeletonLength () {
    if (process.client) {
      if (window.innerWidth < 1024 && window.innerWidth >= 768) return 3;
      return window.innerWidth < 768 ? 1 : 4;
    }
    return 4;
  }
}














































































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { objectIsEmpty } from '~/utils/common.js';

import NavOutline from '~/components/icons/NavOutline.vue';
import StarOutline from '~/components/icons/StarOutline.vue';
import ShareOutline from '~/components/icons/ShareOutline.vue';
import PinOutline from '~/components/icons/PinOutline.vue';
import ActivityOutline from '~/components/icons/ActivityOutline.vue';

type Kpi = {
  icon: any,
  name: string,
};

@Component({
  computed: {
    ...mapState('lang', {
      locale: 'locale',
    }),
  },
})
export default class HomeAppCard extends Vue {
  locale: string;

  appKpis: Array<Kpi> = [
    { icon: PinOutline, name: this.$t('homeAppDiv.appKpis.destination') },
    { icon: NavOutline, name: this.$t('homeAppDiv.appKpis.record') },
    { icon: ActivityOutline, name: this.$t('homeAppDiv.appKpis.track') },
    { icon: StarOutline, name: this.$t('homeAppDiv.appKpis.challenge') },
    { icon: ShareOutline, name: this.$t('homeAppDiv.appKpis.discover') },
  ];

  /* Returns the content to display in the template (reactive for this.locale) */
  get content () {
    return {
      image: '/img/home/homeapp.png',
      kpis: this.appKpis,
      title: this.$t('homeAppDiv.name'),
      name: this.$t('homeAppDiv.title'),
      appleButton: `/img/logos/logo__appstore__${this.locale}@3x.png`,
      appleLink: 'https://apps.apple.com/app/on-piste/id1597319695',
      googleButton: `/img/logos/logo__googleplay__${this.locale}@3x.png`,
      googleLink: 'https://play.google.com/store/apps/details?id=com.rossignol.onpiste',
      imageAlt: this.$t('homeAppDiv.imageAlt'),
    };
  }

  /* Computed skeleton handle */
  get contentIsEmpty () {
    return objectIsEmpty(this.content);
  }
}






























import { Vue, Component } from 'vue-property-decorator';

import SearchOutline from '~/components/icons/SearchOutline.vue';

@Component({
  components: { SearchOutline },
})
export default class GlobalSearchCard extends Vue {
}











































import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

import GlobalSearchCard from '~/components/cards/GlobalSearchCard.vue';

@Component({
  components: { GlobalSearchCard },
  computed: {
    ...mapState('explorer', [
      'displayGlobalSearchInHeader',
    ]),
  },
})
export default class HomeExplorerCard extends Vue {
  @Prop(Array) pictures: string[];

  displayGlobalSearchInHeader: boolean;

  initCrossFade: any = null;

  image: string = '';
  increment: number = 0;

  /* By default the Jumbo will be created with first image */
  created () {
    this.image = this.pictures[0];
  }

  /*
    When component is mounted client-side, we init an interval
    to create the animation of the jumbo
  */
  mounted () {
    const self: any = this;
    this.initCrossFade = setInterval(function () {
      self.increment === self.pictures.length - 1 ? self.increment = 0 : self.increment++;
      self.image = self.pictures[self.increment];
    }, 6000);

    if (process.client) {
      window.addEventListener('scroll', this.checkIfScrollIsUnderDestinationsFeed);
    }
  }

  beforeDestroy () {
    clearInterval(this.initCrossFade);
    if (process.client) {
      window.removeEventListener('scroll', this.checkIfScrollIsUnderDestinationsFeed);
    }
  }

  checkIfScrollIsUnderDestinationsFeed () {
    if (process.client) {
      const destinationsFeed: HTMLElement | null = document.getElementById('jumboSearchCard');

      if (destinationsFeed) {
        if (window.scrollY > (destinationsFeed.offsetHeight + destinationsFeed.offsetTop)) {
          if (!this.displayGlobalSearchInHeader) {
            this.$store.commit('explorer/SET_DISPLAY_SEARCH_IN_MENU', true);
          }
        } else if (window.scrollY < (destinationsFeed.offsetHeight + destinationsFeed.offsetTop)) {
          if (this.displayGlobalSearchInHeader) {
            this.$store.commit('explorer/SET_DISPLAY_SEARCH_IN_MENU', false);
          }
        }
      }
    }
  }
}

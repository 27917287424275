import { snowSports, footSports, bikeSports } from '~/utils/runConstants.js';

export function providerType (type) {
  if (type === 'TODOTOSEE') return 'providerType.todotosee';
  if (type === 'SHOP') return 'providerType.shop';
  if (type === 'FOOD') return 'providerType.food';
  if (type === 'SERVICE') return 'providerType.service';
  if (type === 'HOSTING') return 'providerType.hosting';
  return '';
};

export function eventType (type) {
  if (type === 'COURSE') return 'eventType.course';
  if (type === 'STAY') return 'eventType.stay';
  if (type === 'RACE') return 'eventType.race';
  if (type === 'OTHER') return 'eventType.other';
  if (type === 'EVENT') return 'eventType.event';
  return '';
};

export function routeType (type) {
  if (type === 'ROUTE') return 'routeType.route';
  if (type === 'SPOT') return 'routeType.spot';
  return '';
};

export function providerSubType (type) {
  if (type === 'PICNIC_AREA') return 'providerSubType.picNic';
  if (type === 'FOOD') return 'providerSubType.food';
  if (type === 'ARTISAN') return 'providerSubType.artisan';
  if (type === 'BAR') return 'providerSubType.bar';
  if (type === 'CAMPING') return 'providerSubType.camping';
  if (type === 'BED_AND_BREAKFAST') return 'providerSubType.bedAndBreakfast';
  if (type === 'SHOP') return 'providerSubType.shop';
  if (type === 'ACCOMMODATION') return 'providerSubType.accomodation';
  if (type === 'UNUSUAL_ACCOMMODATION') return 'providerSubType.unusualAccomodation';
  if (type === 'HOTEL') return 'providerSubType.hotel';
  if (type === 'BIKE_RENTAL') return 'providerSubType.bikeRental';
  if (type === 'FURNISHED_GITE') return 'providerSubType.furnishedGite';
  if (type === 'TOURIST_OFFICE') return 'providerSubType.touristOffice';
  if (type === 'NATURAL_HERITAGE') return 'providerSubType.naturalHeritage';
  if (type === 'CULTURAL_HERITAGE') return 'providerSubType.culturalHeritage';
  if (type === 'SERVICE_PROVIDER') return 'providerSubType.serviceProvider';
  if (type === 'LOCAL_PRODUCT') return 'providerSubType.localProduct';
  if (type === 'RESIDENCE') return 'providerSubType.residence';
  if (type === 'RESTAURATION') return 'providerSubType.restauration';
  if (type === 'HEALTH') return 'providerSubType.health';
  if (type === 'BIKE_SERVICE') return 'providerSubType.bikeService';
  if (type === 'SERVICES') return 'providerSubType.services';
  if (type === 'TRANSPORT') return 'providerSubType.transport';
  return '';
}

export function getSportTags (arrayOfSports) {
  const runConstants = [...snowSports, ...bikeSports, ...footSports];
  const sliced = arrayOfSports;
  /* eslint array-callback-return: 0 */
  if (sliced) {
    if (Array.isArray(sliced)) {
      sliced.map((s) => {
        runConstants.map((r) => {
          if (r.iconName === s.icon_name) {
            s.imageName = r.imageName;
          }
        });
      });
    } else {
      runConstants.map((r) => {
        if (r.iconName === sliced.icon_name) {
          sliced.imageName = r.imageName;
        } else if (!sliced.icon_name) {
          if (sliced.parent) {
            const indexIconParent = runConstants.findIndex(obj => obj.iconName === sliced.parent.icon_name);
            sliced.imageName = runConstants[indexIconParent]?.imageName;
          }
        }
      });
    }
    return sliced;
  }
}

export function difficultyHandle (val) {
  switch (val) {
    case 0: return { style: 'unspecified-bg', label: 'routesDifficultyDropdown.insecureLabel', labelStyle: 'unspecified-text' };
    case 1: return { style: 'bg-green-default', label: 'routesDifficultyDropdown.easyLabel', labelStyle: 'text-green-default' };
    case 2: return { style: 'bg-info-default', label: 'routesDifficultyDropdown.normalLabel', labelStyle: 'text-info-default' };
    case 3: return { style: 'bg-red-default', label: 'routesDifficultyDropdown.hardLabel', labelStyle: 'text-red-default' };
    case 4: return { style: 'bg-black', label: 'routesDifficultyDropdown.veryHardLabel', labelStyle: 'text-black' };
    default: return { style: 'bg-info-default', label: 'routesDifficultyDropdown.normalLabel', labelStyle: 'text-info-default' };
  }
}

export function replaceUrl (params, newUrl, objectTitle, extraString) {
  const splittedRouteParams = params.split('-');
  if (splittedRouteParams.length === 1 && !isNaN(Number(splittedRouteParams[0]))) {
    history.replaceState(
      {},
      '',
      `${newUrl}-${splittedRouteParams[0]}${extraString || ''}`,
    );
  } else {
    const id = splittedRouteParams.pop();
    const urlTitle = splittedRouteParams.join('-').trim();
    if (urlTitle !== objectTitle.trim()) {
      history.replaceState(
        {},
        '',
      `${newUrl}-${id}${extraString || ''}`,
      );
    }
  }
}




























import { Vue, Component } from 'vue-property-decorator';

import HomeMainCard from '~/components/cards/HomeMainCard.vue';
import GlobalSwiper from '~/components/partials/GlobalSwiper.vue';

type CardItem = {
  id: number,
  title: string,
  photo: string,
  alt?: string
}

@Component({
  components: { HomeMainCard, GlobalSwiper },
})
export default class HomeMainCards extends Vue {
  universes: Array<CardItem> = [
    {
      id: 0,
      title: this.$t('homeMainCards.universes.ski.title'),
      photo: '/img/home/univers_ski_de_rando_F_1.png',
      alt: this.$t('homeMainCards.universes.ski.alt'),
    },
    {
      id: 1,
      title: this.$t('homeMainCards.universes.trail.title'),
      photo: '/img/home/univers_trail.jpg',
      alt: this.$t('homeMainCards.universes.trail.alt'),
    },
    {
      id: 2,
      title: this.$t('homeMainCards.universes.nordic.title'),
      photo: '/img/home/marche_nordique_2.jpg',
      alt: this.$t('homeMainCards.universes.nordic.alt'),
    },
    {
      id: 3,
      title: this.$t('homeMainCards.universes.bike.title'),
      photo: '/img/home/VTT_Thuria_129.png',
      alt: this.$t('homeMainCards.universes.bike.alt'),
    },
  ];

  /* Skeleton length of fake cards to display based on user's window device */
  get skeletonLength () {
    if (process.client) {
      if (window.innerWidth < 1024 && window.innerWidth >= 768) return 3;
      return window.innerWidth < 768 ? 2 : 4;
    }
    return 4;
  }
}
